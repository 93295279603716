<template>
	<div class="p-grid crud-demo" :key="componentKey">
		<div class="p-col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:left>
						<Button label="New" icon="pi pi-plus" class="p-button-success p-mr-2 p-mb-2" @click="openNew" />
						<Button label="Delete" icon="pi pi-trash" class="p-button-danger p-mb-2" @click="confirmDeleteSelected" :disabled="!selectedIndustries || !selectedIndustries.length" />
					</template>
                </Toolbar>
				<DataTable ref="dt" :value="industries" class="p-datatable-responsive-demo" v-model:selection="selectedIndustries" dataKey="id" :paginator="true" :rows="10" :filters="filters"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} industries" :loading="loading1">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Manage Industries</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Search..." />
                            </span>
						</div>
					</template>
					<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
					<Column field="id" header="ID" :sortable="true" headerStyle="width: 30%"></Column>
					<Column field="name" header="Name" :sortable="true" headerStyle="width: 30%"></Column>
					<Column field="name_uz" header="Name UZ" :sortable="true" headerStyle="width: 30%"></Column>
					<Column field="name_ru" header="Name RU" :sortable="true" headerStyle="width: 30%"></Column>
					<Column field="parent_name" header="Parent" :sortable="true" headerStyle="width: 30%"></Column>
					<Column headerStyle="width: 10%">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editIndustry(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteIndustry(slotProps.data)" />
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="industryDialog" :style="{width: '800px'}" header="Industry Details" :modal="true" class="p-fluid">
					<div class="p-formgrid p-grid p-jc-center">
						<div class="p-field p-col-6">
							<label for="name">Name</label>
							<InputText id="name" v-model.trim="industry.name" required="true" autofocus :class="{'p-invalid': submitted && !industry.name}" />
							<small class="p-invalid" v-if="submitted && !industry.name">Name is required.</small>
						</div>
						<div class="p-field p-col-6">
							<label for="name">Name UZ</label>
							<InputText id="name" v-model.trim="industry.name_uz" required="true" autofocus :class="{'p-invalid': submitted && !industry.name_uz}" />
							<small class="p-invalid" v-if="submitted && !industry.name_uz">Name is required.</small>
						</div>
						<div class="p-field p-col-6">
							<label for="name">Name RU</label>
							<InputText id="name" v-model.trim="industry.name_ru" required="true" autofocus :class="{'p-invalid': submitted && !industry.name_ru}" />
							<small class="p-invalid" v-if="submitted && !industry.name_ru">Name is required.</small>
						</div>
						<div class="p-field p-col-6">
							<label for="parent">Parent</label>
							<Dropdown id="parent" v-model="dropdownItem" :options="dropdownItems" optionLabel="parent_name" dataKey="parent_id" placeholder="Select One" :editable="true" />
						</div>
						<div class="p-field p-col-12">
							<label for="slug">Slug</label>
							<InputText id="slug" v-model.trim="industry.slug" required="true" autofocus :class="{'p-invalid': submitted && !industry.slug}" />
							<small class="p-invalid" v-if="submitted && !industry.slug">Slug is required.</small>
						</div>
						
						<div class="p-field p-col-12">
							<label for="content">Content</label>
							<Editor v-model="industry.description" editorStyle="height: 320px" required="true" id="content"/>
						</div>
						<div class="p-field p-col-12">
							<label for="content">Content RU</label>
							<Editor v-model="industry.description_ru" editorStyle="height: 320px" required="true" id="content"/>
						</div>
						<div class="p-field p-col-12">
							<label for="content">Content UZ</label>
							<Editor v-model="industry.description_uz" editorStyle="height: 320px" required="true" id="content"/>
						</div>
					
						<div class="p-field p-col-12 p-md-6">
							<label for="industry_photo">Industry image</label>
							<br>
							<!-- <img :src="'https://advantex.uz/public/assets/images/advantex/industry/'+industry.photo" :alt="industry.photo" class="industry-image-modal" v-if="industry.photo" /> -->
							<img :src="'https://advantex.uz/public/assets/images/advantex/industry/'+industry.photo" :alt="industry.photo" class="industry-image-modal" v-if="industry.photo" />
							<label v-else class="italic"> No photo </label> <br/> <br/>
							<input type="file" id="industry_photo" ref="file" @change="handleFileUpload"/>
							<!-- <Button label="+ Choose file" class="p-button-secondary" @click="onClickSelectImage" /> -->
						</div>
					</div>
					<template #footer>
						<Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Save" icon="pi pi-check" class="p-button-text" @click="saveIndustry" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteIndustryDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="industry">Are you sure you want to delete <b>{{industry.name}}</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteIndustryDialog = false"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteIndustry" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteIndustriesDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="industry">Are you sure you want to delete the selected industries?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteIndustriesDialog = false"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedIndustries" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import IndustryService from '../service/IndustriesService';

export default {
	data() {
		return {
			industries: [],
			industryDialog: false,
			deleteIndustryDialog: false,
			deleteIndustriesDialog: false,
			industry: {},
			selectedIndustries: null,
			filters: {},
			submitted: false,
			dropdownItems: [],
			dropdownItem: {parent_name:'None', parent_id:'0'},
			loading1: true,
			componentKey: 0,
		}
	},
	industryService: null,
	
	created() {
		this.industryService = new IndustryService();
	},
	
	mounted() {
		this.onMounted()
	},
	
	methods: {
		onMounted() {
			this.industryService.getIndustries().then(data => {
				if (data.isLogged){
					this.industries = data.fetch_data
					for (let i = 0; i < this.industries.length; i++) {
						for (let j = 0; j < this.industries.length; j++) {
							if (this.industries[i].parent_id == this.industries[j].id) {
								this.industries[i].parent_name = this.industries[j].name
							}
						}
						if (this.industries[i].parent_id == "0") {
							this.industries[i].parent_name = "None"
						}
					}
					this.dropdownItems = this.industries.map(industry =>{
						const item = {}
						item.parent_name = industry.name
						item.parent_id = industry.id
						return item
					})
					this.dropdownItems.unshift({parent_name:'None', parent_id:'0'})
					this.loading1 = false
				} else {
					this.$router.push('Login')
				}
			});
		},
		
		openNew() {
			this.industry = {};
			this.submitted = false;
			this.dropdownItem = {parent_name:'None', parent_id:'0'}

			this.industryDialog = true;
			
		},
		
		handleFileUpload(event){
			const file = event.target.files[0]
			this.url = URL.createObjectURL(file)
			this.industry.photo = event.target.files[0]
		},
		
		hideDialog() {
			this.industryDialog = false;
			this.submitted = false;
        },
		
		saveIndustry() {
			this.submitted = true;
			
			this.industry.parent_id = this.dropdownItem.parent_id
			this.industry.parent_name = this.dropdownItem.parent_name
			
			if (this.industry.id) {
				this.industryService.updateIndustry(this.industry)
					.then(response => {
						console.log(response)
						return response
					})
					.then(response => {
						this.industryDialog = false;
						return response
					})
					.then(response => {
						this.$toast.add({severity:'success', summary: 'Successful', detail: response.data.fetch_data, life: 3000});
						return response
					})
					.then(response => {
						this.onMounted()
						return response
					}) 
					.catch(error => {
						console.log(error)
					})
			} else {
				this.industryService.insertIndustry(this.industry)
					.then(response => {
						console.log(response)
						return response
					})
					.then(response => {
						this.industryDialog = false;
						return response
					})
					.then(response => {
						this.$toast.add({severity:'success', summary: 'Successful', detail: response.data.fetch_data, life: 3000});
						return response
					})
					.then(response => {
						this.onMounted()
						return response
					}) 
					.catch(error => {
						console.log(error)
					})
			}
			this.industryDialog = false;
		},
		
		editIndustry(industry) {
			this.industry = {...industry};
			this.dropdownItem = this.dropdownItems.find(item => item.parent_id == this.industry.parent_id)
			this.industryDialog = true;
		},
		
		confirmDeleteIndustry(industry) {
			this.industry = industry;
			this.deleteIndustryDialog = true;
		},
		
		deleteIndustry() {
			this.industryService.deleteIndustry(this.industry)
				.then(response => {
					console.log(response)
					return response
				})
				.then(response => {
					this.deleteIndustryDialog = false;
					return response
				})
				.then(response => {
					this.$toast.add({severity:'success', summary: 'Successful', detail: response.data.fetch_data, life: 3000});
					return response
				})
				.then(response => {
					this.onMounted()
					return response
				}) 
				.catch(error => {
					console.log(error)
				})
		},
		
		confirmDeleteSelected() {
			this.deleteIndustriesDialog = true;
		},
		
		deleteSelectedIndustries() {
			this.industries = this.industries.filter(val => !this.selectedIndustries.includes(val));
			this.deleteIndustriesDialog = false;
			this.selectedIndustries = null;
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'Industries Deleted', life: 3000});
		}
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.industry-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.industry-image-modal {
	width: 100%;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	margin: 1rem 0;
	display: block;
}
.p-dialog .industry-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.industry-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-published {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-saved {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
