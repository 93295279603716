import FormData from 'form-data';

import axios from "axios";
axios.defaults.withCredentials = true;

export default class IndustryService {

	async getIndustries() {
		return await axios.get('https://advantex.uz/industry/getAllIndustries').then(res => res.data);
	}

	updateIndustry(industry) {
		var data = new FormData()
		data.append('name', industry.name)
		data.append('name_ru', industry.name_ru)
		data.append('name_uz', industry.name_uz)
		data.append('title', industry.name)
		data.append('slug', industry.slug)
		data.append('description', industry.description)
		data.append('description_ru', industry.description_ru)
		data.append('description_uz', industry.description_uz)
		data.append('parent_id', industry.parent_id)
		data.append('photo', industry.photo)
		data.append('id', industry.id)

		var config = {
			method: 'post',
			url: 'https://advantex.uz/industry/updateIndustry',
			data: data
		}

		return axios(config)
	}

	insertIndustry(industry) {
		var data = new FormData()
		data.append('name', industry.name)
		data.append('name_ru', industry.name_ru)
		data.append('name_uz', industry.name_uz)
		data.append('title', industry.name)
		data.append('slug', industry.slug)
		data.append('description', industry.description)
		data.append('description_ru', industry.description_ru)
		data.append('description_uz', industry.description_uz)
		data.append('parent_id', industry.parent_id)
		data.append('photo', industry.photo)

		var config = {
			method: 'post',
			url: 'https://advantex.uz/industry/insertIndustry',
			data: data
		}

		return axios(config)
	}

	deleteIndustry(industry) {
		var data = JSON.stringify({
			"id": industry.id
		});

		var config = {
			method: 'delete',
			url: 'https://advantex.uz/industry/deleteIndustry',
			headers: {
				'Content-Type': 'application/json',
			},
			data: data
		};

		return axios(config)
	}
}